import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {HeaderComponent} from './header/header.component';
import {FooterComponent} from './footer/footer.component';
import {MagnificPopupModule} from '../magnific-popup';
import {TrackingModule} from '../tracking/tracking.module';

@NgModule({
  imports: [
    CommonModule,
    MagnificPopupModule,
    TrackingModule
  ],
  declarations: [
    HeaderComponent,
    FooterComponent
  ],
  exports: [
    HeaderComponent,
    FooterComponent
  ]
})
export class SharedModule {
}
