import {ModuleWithProviders, NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {MagnificPopupService} from './magnific-popup.service';
import {MagnificPopupInlineDirective} from './magnific-popup-inline.directive';
import {MagnificPopupOpenInlineDirective} from './magnific-popup-open-inline.directive';
import {MagnificPopupIframeDirective} from './magnific-popup-iframe.directive';

@NgModule({
  imports: [
    CommonModule
  ],
  declarations: [
    MagnificPopupIframeDirective,
    MagnificPopupInlineDirective,
    MagnificPopupOpenInlineDirective
  ],
  exports: [
    MagnificPopupIframeDirective,
    MagnificPopupInlineDirective,
    MagnificPopupOpenInlineDirective
  ]
})
export class MagnificPopupModule {
  static forRoot(): ModuleWithProviders {
    return {
      ngModule: MagnificPopupModule,
      providers: [
        MagnificPopupService
      ]
    };
  }
}
