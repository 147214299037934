import {AfterViewInit, Directive, ElementRef, HostBinding, Input} from '@angular/core';
import {MagnificPopupService} from './magnific-popup.service';

@Directive({
  selector: '[appMagnificPopupInline],[magnificPopupInline]'
})
export class MagnificPopupInlineDirective implements AfterViewInit {
  @HostBinding('class.mfp-hide') @Input() magnificPopupInline: string;
  @Input() options: any = {};

  constructor(private el: ElementRef, private popup: MagnificPopupService) {
  }

  ngAfterViewInit() {
    this.el.nativeElement.id = this.magnificPopupInline;
    this.popup.addInline(this.magnificPopupInline, this.el, this.options);
  }
}
