import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';
import {MnFullpageModule} from 'ngx-fullpage';
import {OwlModule} from 'ngx-owl-carousel';
import {ResponsiveModule, IResponsiveConfig} from 'ngx-responsive'

import {AppComponent} from './app.component';

import {AboutComponent} from './section/about/about.component';
import {QuizComponent} from './section/quiz/quiz.component';
import {QuestionViewComponent} from './section/quiz/question-view/question-view.component';
import {ArchiveComponent} from './section/archive/archive.component';
import {BlogsListComponent} from './section/blogs-list/blogs-list.component';
import {BlogsListItemComponent} from './section/blogs-list/blogs-list-item/blogs-list-item.component';
import {BlogVotesComponent} from './blog-votes/blog-votes.component';
import {BlogDetailsComponent} from './blog-details/blog-details.component';
import {MagnificPopupModule} from './modules/magnific-popup';
import {BlogActionsComponent} from './blog-actions/blog-actions.component';
import {HttpClientModule} from '@angular/common/http';
import {BlogRotatePanelComponent} from './blog-rotate-panel/blog-rotate-panel.component';
import {BlogPreviewComponent} from './blog-preview/blog-preview.component';
import {BlogLogoDirective} from './blog-logo.directive';
import {CookieAgreementService} from './services/cookie-agreement.service';
import {JWT_OPTIONS, JwtModule} from '@auth0/angular-jwt';
import {getCookie} from './utilites/get-cookie';
import {AuthService} from './services/auth.service';
import {UserService} from './services/user.service';
import {ServiceWorkerModule} from '@angular/service-worker';
import {environment} from '../environments/environment';
import {BlogsService} from './blogs.service';
import {QuizService} from './quiz.service';
import {PerfectScrollbarModule} from 'ngx-perfect-scrollbar';
import {NgxFacebookModule} from '@shtrihpunktir/ngx-facebook';
import {ReactiveFormsModule, FormsModule} from '@angular/forms';
import {SharedModule} from './modules/shared/shared.module';
import {TrackingModule} from './modules/tracking/tracking.module';

const tokenGetter = () => {
  return getCookie('access_token');
};

export function jwtOptionsFactory() {
  return {tokenGetter};
}

const responsiveConfig: IResponsiveConfig = {
  breakPoints: {
      xs: {max: 600},
      sm: {min: 601, max: 959},
      md: {min: 960, max: 1279},
      lg: {min: 1280, max: 1919},
      xl: {min: 1920}
  },
  debounceTime: 100
};

@NgModule({
  declarations: [
    AppComponent,
    AboutComponent,
    QuizComponent,
    QuestionViewComponent,
    ArchiveComponent,
    BlogsListComponent,
    BlogsListItemComponent,
    BlogVotesComponent,
    BlogDetailsComponent,
    BlogActionsComponent,
    BlogRotatePanelComponent,
    BlogPreviewComponent,
    BlogLogoDirective,
  ],
  imports: [
    BrowserModule,
    FormsModule,
    ResponsiveModule.forRoot(responsiveConfig),
    HttpClientModule,
    ReactiveFormsModule,
    SharedModule,
    TrackingModule,
    JwtModule.forRoot({
      jwtOptionsProvider: {
        provide: JWT_OPTIONS,
        useFactory: jwtOptionsFactory,
      }
    }),
    MnFullpageModule.forRoot(),
    OwlModule,
    MagnificPopupModule.forRoot(),
    PerfectScrollbarModule,
    NgxFacebookModule.forRoot({
      appId: '300243793951941',
      xfbml: true,
      version: 'v3.0'
    }),
    ServiceWorkerModule.register('/ngsw-worker.js', {enabled: environment.production})
  ],
  providers: [
    {provide: BlogsService, useClass: BlogsService},
    {provide: QuizService, useClass: QuizService},
    CookieAgreementService,
    AuthService,
    UserService
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
