import {Injectable} from '@angular/core';
import {AuthService} from './auth.service';
import {User} from '../user';
import {JwtHelperService} from '@auth0/angular-jwt';

@Injectable()
export class UserService {
  constructor(private auth: AuthService, private jwt: JwtHelperService) {
  }

  get name() {
    return this.data && this.data.displayName;
  }

  get id() {
    return this.data && this.data.id;
  }

  private get data(): User {
    if (this.auth.isAuthenticated) {
      return this.jwt.decodeToken(this.auth.token).data;
    } else {
      return null;
    }
  }
}
