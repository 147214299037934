import {ChangeDetectionStrategy, Component, EventEmitter, OnInit, Output, Input} from '@angular/core';
import {MagnificPopupService} from 'src/app/modules/magnific-popup';
import {Blog} from 'src/app/blog';

@Component({
  selector: 'app-archive, [app-archive].section-archive',
  templateUrl: './archive.component.html',
  styleUrls: ['./archive.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ArchiveComponent implements OnInit {
  @Input() items: Array<Blog> = [];
  @Output() goback = new EventEmitter();

  constructor(
    private readonly popup: MagnificPopupService,
  ) {
  }

  ngOnInit() {
    console.log('items', this.items)
  }

  viewBlogVideo(blog: Blog) {
    if (blog.video) {
      this.popup.openIframe(blog.video);
    } else {
      this.popup.openInline('video-not-found');
    }
  }

  clickGoBack() {
    this.goback.emit();
  }

}
