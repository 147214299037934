import {ChangeDetectionStrategy, Component, EventEmitter, Input, Output} from '@angular/core';
import {Blog} from '../blog';
import {BlogsService} from '../blogs.service';

@Component({
  selector: 'app-blog-actions, blog-actions',
  templateUrl: './blog-actions.component.html',
  styleUrls: ['./blog-actions.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BlogActionsComponent {
  @Input() blog: Blog;

  @Output() vote = new EventEmitter();
  @Output() view = new EventEmitter();

  constructor(private blogsService: BlogsService) {
  }

  clickViewVideo($event) {
    $event.preventDefault();
    $event.stopPropagation();
    this.view.emit(this.blog);
  }

  clickVote($event) {
    $event.preventDefault();
    $event.stopPropagation();
    this.vote.emit(this.blog);
  }

  get userVotesCount() {
    return this.blogsService.getUserVotesCount();
  }
}
