import {Injectable} from '@angular/core';
import {MagnificPopupService} from '../modules/magnific-popup';
import {BehaviorSubject} from 'rxjs';

@Injectable()
export class CookieAgreementService {
  private _accepted$ = new BehaviorSubject(this.accepted);

  constructor(private popup: MagnificPopupService) {
  }

  request() {
    if (!this.accepted) {
      this.popup.openInline('cookies-notice');
    }
  }

  get accepted$() {
    return this._accepted$.asObservable();
  }

  get accepted() {
    const stored = localStorage.getItem('cookies-notice');
    return stored && JSON.parse(stored);
  }

  accept() {
    this.popup.close();
    localStorage.setItem('cookies-notice', JSON.stringify(true));
    this._accepted$.next(true);
  }
}
