import {ChangeDetectionStrategy, Component, Input} from '@angular/core';
import {Blog} from '../blog';

@Component({
  selector: 'app-blog-rotate-panel,blog-rotate-panel',
  templateUrl: './blog-rotate-panel.component.html',
  styleUrls: ['./blog-rotate-panel.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BlogRotatePanelComponent {
  @Input() blogs: Array<Blog> = [];
  @Input() currentIdx = 0;

  constructor() {
  }

  get blog() {
    return this.blogs[this.currentIdx];
  }
}
