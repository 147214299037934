import {Component, Input} from '@angular/core';
import {Blog} from '../blog';

@Component({
  selector: 'app-blog-details,blog-details',
  templateUrl: './blog-details.component.html',
  styleUrls: ['./blog-details.component.scss'],
})
export class BlogDetailsComponent {
  @Input() blog: Blog;

  get description() {
    return this.blog.company.description || this.blog.company.full_description;
  }
}
