import {Injectable} from '@angular/core';
import {Blog} from './blog';
import {BehaviorSubject, interval, merge, of, throwError} from 'rxjs';
import {BLOGS_DATA} from './data/blogs';
import {BLOGS_ATCHIVE_PART1_DATA} from './data/blogs-archive';
import {HttpClient, HttpErrorResponse} from '@angular/common/http';
import {catchError, distinctUntilChanged, switchMap, takeWhile, tap} from 'rxjs/operators';
import {AuthService} from './services/auth.service';
import {environment} from '../environments/environment';


@Injectable()
export class BlogsService {
  private userVotesCount = new BehaviorSubject(0);
  private votesLimit = 0;
  public isFinished = false;

  constructor(private http: HttpClient, private auth: AuthService) {
    // this.runUpdates();

    this.http.get(`${environment.apiBaseUrl}/api/votes/config`).pipe(
      catchError(() => of({limit: 0, isFinished: false})),
      tap(({limit, isFinished}: { limit: number, isFinished: boolean }) => {
        this.userVotesCount.next(limit);
        this.votesLimit = limit;
        this.isFinished = isFinished;
      }),
      switchMap(() => this.updateUserVotes())
    ).subscribe();
  }

  getBlogs() {
    return BLOGS_DATA;
  }

  getBlogsArchive(seasson: number): Blog[] {
    switch(seasson) {
      case 1: return BLOGS_ATCHIVE_PART1_DATA;
      default: return [];
    }
  }

  getUserVotesCount() {
    return this.userVotesCount
      .pipe(
        distinctUntilChanged()
      );
  }

  vote(blog: Blog) {
    return this.http.put(`${environment.apiBaseUrl}/api/votes`, {target: blog.id})
      .pipe(
        catchError((err: HttpErrorResponse) => {
          switch (err.status) {
            case 401:
              return throwError(new Error('forbidden'));
            default:
              return throwError(err);
          }
        }),
        switchMap(() => this.updateAll())
      );
  }

  private updateAll() {
    return merge(this.updateVotes(), this.updateUserVotes());
  }

  updateVotes() {
    return this.http.get(`${environment.apiBaseUrl}/api/blog-votes`)
      .pipe(
        catchError(() => of([])),
        tap((data: [{ blog: string, votes: number }]) => {
          BLOGS_DATA.forEach(blog => {
            const item = data.find(i => i.blog === blog.id);
            blog.votes = item ? item.votes : 0;
          });
        })
      );
  }

  private updateUserVotes() {
    return this.http.get<[any]>(`${environment.apiBaseUrl}/api/votes`)
      .pipe(
        catchError(() => of([])),
        tap((data) => {
          this.userVotesCount.next(this.votesLimit - data.length);
        })
      );
  }


  private runUpdates() {
    this.http.get(`${environment.apiBaseUrl}/api/votes/config`)
      .pipe(
        catchError(() => of({limit: 0})),
        tap(({limit}: { limit: number }) => {
          this.userVotesCount.next(limit);
          this.votesLimit = limit;
        }),
        switchMap(() => this.updateAll()),
        switchMap(() => interval(5000)),
        takeWhile(() => this.auth.isAuthenticated),
        switchMap(() => this.updateAll())
      )
      .subscribe(
        () => console.log('updated'),
      );
  }
}
