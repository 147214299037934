import {ChangeDetectionStrategy, Component, EventEmitter, OnInit, Output} from '@angular/core';
import {AuthService} from '../../../services/auth.service';
import {UserService} from '../../../services/user.service';


@Component({
  selector: 'app-header, header.page-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class HeaderComponent implements OnInit {
  @Output() navtoggle = new EventEmitter();
  @Output() navhome = new EventEmitter();

  get isAuthenticated() {
    return this.auth.isAuthenticated;
  }

  constructor(private auth: AuthService, public user: UserService) {
  }

  ngOnInit() {
  }


  clickNavToggle() {
    this.navtoggle.emit();
  }

  clickLogo() {
    this.navhome.emit();
  }
}
