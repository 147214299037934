import {ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, Output} from '@angular/core';
import {Blog} from '../../blog';

@Component({
  selector: 'app-blogs-list, [app-blogs-list].section-blogs',
  templateUrl: './blogs-list.component.html',
  styleUrls: ['./blogs-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BlogsListComponent implements OnChanges {
  @Input() items: Array<Blog> = [];
  @Output() select = new EventEmitter();
  @Output() goback = new EventEmitter();

  ngOnChanges() {
    console.log('list changes');
  }

  itemSelected(blog: Blog) {
    this.select.emit(blog);
  }

  clickGoBack() {
    this.goback.emit();
  }

}
