import {Directive, HostListener, Input} from '@angular/core';
import {MagnificPopupService} from './magnific-popup.service';

@Directive({
  selector: 'a[magnificPopupOpenInline],[appMagnificPopupOpenInline]'
})
export class MagnificPopupOpenInlineDirective {
  @Input() href: string;

  constructor(private popup: MagnificPopupService) {
  }

  @HostListener('click', ['$event'])
  open($event) {
    if (this.href[0] !== '#') {
      return;
    }
    const trimmed = this.href.substr(1);
    if (trimmed === '') {
      return;
    }

    $event.preventDefault();
    this.popup.openInline(trimmed);
    this.popup.openInline(trimmed);
  }
}
