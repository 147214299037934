import {ElementRef, Injectable} from '@angular/core';

const $: any = (window as any).$ || {};

@Injectable()
export class MagnificPopupService {
  private inlines = new Map<string, { element: ElementRef, options?: any }>();
  private hasOpened = false;

  constructor() {

  }

  @Available
  addInline(id: string, element: ElementRef, options?) {
    if (id) {
      this.inlines.set(id, {element, options});
    }
  }

  @Available
  openInline(id) {
    const popup = this.inlines.get(id);
    if (popup) {
      this.open('inline', {src: `#${id}`}, popup.options);
    }
  }

  @Available
  openIframe(src) {
    this.open('iframe', {src});
  }

  @Available
  close() {
    this.magnific.instance.close();
  }

  afterClose = () => this.hasOpened = false;

  protected open(type, items, options = {}) {
    if (this.hasOpened) {
      console.log('not all, guys');
      return;
    }

    this.hasOpened = true;
    this.magnific.open({
      ...options,
      type,
      items,
      callbacks: {
        afterClose: this.afterClose
      },
    });
  }

  protected get magnific() {
    return $.magnificPopup;
  }
}


function Available(target, key, descriptor) {
  // save a reference to the original method this way we keep the values currently in the
  // descriptor and don't overwrite what another decorator might have done to the descriptor.
  if (descriptor === undefined) {
    descriptor = Object.getOwnPropertyDescriptor(target, key);
  }
  const originalMethod = descriptor.value;

  // editing the descriptor/value parameter
  descriptor.value = function (...args) {
    if ($.magnificPopup) {
      // note usage of originalMethod here
      return originalMethod.apply(this, args);
    }
    return null;
  };

  // return edited descriptor as opposed to overwriting the descriptor
  return descriptor;
}

/*
c = {
  iframe: {
    markup: '<div class="mfp-iframe-scaler">' +
    '<div class="mfp-close"></div>' +
    '<iframe class="mfp-iframe" frameborder="0" allowfullscreen></iframe>' +
    '</div>', // HTML markup of popup, `mfp-close` will be replaced by the close button

    patterns:
      {
        youtube: {
          index: 'youtube.com/', // String that detects type of video (in this case YouTube). Simply via url.indexOf(index).

          id:
            'v=', // String that splits URL in a two parts, second part should be %id%
          // Or null - full URL will be returned
          // Or a function that should return %id%, for example:
          // id: function(url) { return 'parsed id'; }

          src:
            '//www.youtube.com/embed/%id%?autoplay=1' // URL that will be set as a source for iframe.
        }

      }
  }
};
*/
