import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {GtmTrackDirective} from './directives/gtm-track.directive';
import {Angulartics2GoogleTagManager} from 'angulartics2/gtm';
import {Angulartics2RouterlessModule} from 'angulartics2/routerlessmodule';

@NgModule({
  imports: [
    CommonModule,
    Angulartics2RouterlessModule.forRoot([Angulartics2GoogleTagManager], {
      pageTracking: {
        clearHash: true
      },
    }),
  ],
  declarations: [
    GtmTrackDirective
  ],
  exports: [
    GtmTrackDirective
  ]
})
export class TrackingModule {
}
