import {Directive, HostListener, Input} from '@angular/core';
import {MagnificPopupService} from './magnific-popup.service';

@Directive({
  selector: 'a[appMagnificPopupIframe],a[magnificPopupIframe]'
})
export class MagnificPopupIframeDirective {
  @Input() href: string;

  constructor(private service: MagnificPopupService) {
  }

  @HostListener('click', ['$event'])
  show($event) {
    $event.preventDefault();
    this.service.openIframe(this.href);
  }
}
