import {Injectable} from '@angular/core';
import {Blog} from './blog';
import {BehaviorSubject, interval, merge, of, throwError} from 'rxjs';
import {QUIZ_QUESTIONS_DATA,QUIZ_RESULTS_DATA} from './data/quiz';
import {HttpClient, HttpErrorResponse} from '@angular/common/http';
import {catchError, distinctUntilChanged, switchMap, takeWhile, tap} from 'rxjs/operators';
import {AuthService} from './services/auth.service';
import {environment} from '../environments/environment';


@Injectable()
export class QuizService {
  private _isStarted$ = new BehaviorSubject(this.isStarted);
  private _isComplete$ = new BehaviorSubject(this.isComplete);

  private _canGetGift$ = new BehaviorSubject(this.canGetGift);
  private _isEmailSendet$ = new BehaviorSubject(this.isEmailSendet);

  constructor(
    private http: HttpClient,
    private auth: AuthService
  ) {
    // localStorage.
    // this.runUpdates();

    // this.http.get(`${environment.apiBaseUrl}/api/votes/config`).pipe(
    //   catchError(() => of({limit: 0, isFinished: false})),
    //   tap(({limit, isFinished}: { limit: number, isFinished: boolean }) => {
    //     this.userVotesCount.next(limit);
    //     this.votesLimit = limit;
    //     this.isFinished = isFinished;
    //   }),
    //   switchMap(() => this.updateUserVotes())
    // ).subscribe();
  }

  get isStarted$() {
    return this._isStarted$.asObservable();
  }

  get isStarted() {
    const stored = localStorage.getItem('quiz-is-started');
    return stored && JSON.parse(stored);
  }

  get isComplete$() {
    return this._isComplete$.asObservable();
  }

  get isComplete() {
    const stored = localStorage.getItem('quiz-is-complete');
    return stored && JSON.parse(stored);
  }

  get canGetGift() {
    const stored = localStorage.getItem('quiz-can-get-gift');
    return stored && JSON.parse(stored);
  }

  get canGetGift$() {
    return this._canGetGift$.asObservable();
  }

  get isEmailSendet() {
    const stored = localStorage.getItem('quiz-email-sendet');
    return stored && JSON.parse(stored);
  }

  get isEmailSendet$() {
    return this._isEmailSendet$.asObservable();
  }

  startQuiz() {
    localStorage.setItem('quiz-is-started', JSON.stringify(true));
    this._isStarted$.next(true);
  }

  restartQuiz() {
    localStorage.removeItem('quiz-is-started');
    localStorage.removeItem('quiz-is-complete');
    localStorage.removeItem('quiz-result-data');
    localStorage.removeItem('quiz-can-get-gift');
    localStorage.removeItem('quiz-email-sendet');
    
    this._isStarted$.next(false);
    this._isComplete$.next(false);
    
    this._canGetGift$.next(false);
    this._isEmailSendet$.next(false);
  }

  completeQuiz(summary, responseArr, responseMap) {
    const result = this.getResults().find((el) => {
      return summary >= el.minVal && summary <= el.maxVal;
    })

    localStorage.setItem('quiz-is-complete', JSON.stringify(true));
    localStorage.setItem('quiz-result-data', JSON.stringify({
      summary: summary,
      result: result,
      responseArr: responseArr,
      responseMap: responseMap
    }));

    this.saveResultToServer(summary, responseArr, responseMap)
      .subscribe((result: any) => {
        localStorage.setItem('quiz-can-get-gift', JSON.stringify(result.canGetGift));
        this._canGetGift$.next(result.canGetGift)
      });

    this._isComplete$.next(true);
  }

  saveResultToServer(summary, responseArr, responseMap) {
    return this.http.put(`${environment.apiBaseUrl}/api/quiz`, {
      summary: summary,
      responseArr: JSON.stringify(responseArr),
      responseMap: JSON.stringify(responseMap)
    })
    .pipe(
      catchError((err: HttpErrorResponse) => {
        switch (err.status) {
          case 401:
            return throwError(new Error('forbidden'));
          default:
            return throwError(err);
        }
      }),
      tap((data) => {
        return data;
      })
    );
  }

  saveEmail(giftEmail) {
    this.saveEmailToServer(giftEmail)
    .subscribe((data) => {
      localStorage.setItem('quiz-email-sendet', JSON.stringify(true));
      this._isEmailSendet$.next(true)
      console.log('email save', data)
    })
  }

  saveEmailToServer(giftEmail) {
    return this.http.put(`${environment.apiBaseUrl}/api/quiz`, {
      giftEmail: giftEmail
    })
    .pipe(
      catchError((err: HttpErrorResponse) => {
        switch (err.status) {
          case 401:
            return throwError(new Error('forbidden'));
          default:
            return throwError(err);
        }
      }),
      tap((data) => {
        return data;
      })
    );
  }

  getQuizResult() {
    const stored = localStorage.getItem('quiz-result-data');
    let resultData = null;

    if(resultData = stored && JSON.parse(stored)) {
      return resultData;
    } else {
      return null
    }
  }

  getQuestions() {
    return QUIZ_QUESTIONS_DATA;
  }

  getResults() {
    return QUIZ_RESULTS_DATA;
  }
  
}
