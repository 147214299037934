import {Blog} from '../blog';
/* tslint:disable:max-line-length */
export const BLOGS_ATCHIVE_PART1_DATA: Array<Blog> = [
  {
    id: 'pobedinskiy',
    votes: 0,
    video: 'https://www.youtube.com/watch?v=xRDDapA3eZA',
    links: {
      vkontakte: 'https://vk.com/pobedos',
      instagram: 'https://www.instagram.com/pobedinskiy/',
      youtube: 'https://www.youtube.com/user/PhysFromPobed',
      facebook: 'https://www.facebook.com/PhysFromPobed/?ref=br_rs',
    },
    aboutBg: 'images/slider/blogger_01_bg.jpg',
    color: '#9673b4',
    cover: 'images/bloggers/bloggers_01.jpg',
    logoLg: 'images/blog-logo-lg-1.png',
    backgroundImage: 'images/blog-1.jpg',
    logo: 'images/blog-logo-1.png',
    title: 'Физика от Побединского',
    title_parts: ['ФИЗИКА', 'ОТ ПОБЕДИНСКОГО'],
    category: 'Какие атомы на ощупь?',
    company: {
      title: 'NT-MDT',
      link: 'https://www.ntmdt-si.ru/',
      picture: 'images/location-1.jpg',
      description: 'Как рассмотреть то самое «нано»? Опытом поделились в NT-MDT, где более 25 лет команда завода разрабатывает исследовательское оборудование для нанотехнологий.',
      full_description: 'Более 25 лет команда НТ-МДТ Спектрум Инструментс занимается разработкой, производством и поддержкой исследовательского оборудования для нанотехнологий. За эти годы было произведено более 4000 систем, установленных в более чем 60 странах мира. Это независимая компания с филиалами по всему миру, которая создает современное измерительное оборудование для промышленности и научных исследований на основе сканирующей зондовой микроскопии (СЗМ) в сочетании с оптическими методиками. Приборы применимы практически во всех областях науки и техники, от изучения структуры ДНК до диагностики состояния металла роторов паровых турбин.'
    }
  }, {
    id: 'nauchpok',
    votes: 0,
    video: 'https://www.youtube.com/watch?v=AkMGP9me0DQ',
    links: {
      vkontakte: 'https://vk.com/nikitinaa',
      youtube: 'https://www.youtube.com/user/nowchpok',
      instagram: 'https://www.instagram.com/alenasaur/',
    },
    aboutBg: 'images/slider/blogger_02_bg.jpg',
    color: '#02a6e3',
    cover: 'images/bloggers/bloggers_02.jpg',
    logoLg: 'images/blog-logo-lg-2.png',
    title: 'НАУЧПОК',
    category: 'Как ищут РАК?',
    backgroundImage: 'images/blog-2.jpg',
    logo: 'images/blog-logo-2.png',
    company: {
      title: 'ПЭТ-ТЕХНОЛОДЖИ',
      link: 'https://www.pet-net.ru/',
      picture: 'images/location-2.jpg',
      description: 'Как глюкоза помогает искать опухоли? Можно ли провести онкооперацию без разрезов? Делают ли такое в России? Да! Как точно ставят диагнозы, смотрите в видео.',
      full_description: 'Федеральная сеть центров ядерной медицины “ПЭТ-Технолоджи” проводит высокоточные исследования организма для выявления опухолей на ранних стадиях. В основе метода лежат современные технологии, которые применяются в Европе и США. В «ПЭТ-Технолоджи» не только выявляют, но и удаляют опухоли с помощью технологии «Кибер-Нож». Это безболезненный метод лечения, позволяющий победить недуг быстро и эффективно.'
    }
  }, {
    id: 'scione',
    votes: 0,
    video: 'https://www.youtube.com/watch?v=wVhBotHQ1uU',
    links: {
      youtube: 'https://www.youtube.com/channel/UCSaVoRErW4kqKsDqExs2MXA/videos',
      facebook: 'https://www.facebook.com/sci.one.tv',
      vkontakte: 'https://vk.com/sci_one',
      instagram: 'https://www.instagram.com/sci_one_tv/',
    },
    aboutBg: 'images/slider/blogger_03_bg.jpg',
    color: '#5e656d',
    cover: 'images/bloggers/bloggers_03.jpg',
    logoLg: 'images/blog-logo-lg-3.png',
    title: 'SciOne',
    category: 'Самый совершенный материал',
    backgroundImage: 'images/blog-3.jpg',
    logo: 'images/blog-logo-3.png',
    company: {
      title: 'НАНОТЕХНОЛОГИЧЕСКИЙ ЦЕНТР КОМПОЗИТОВ',
      link: 'http://www.nccrussia.com/ru/',
      picture: 'images/location-3.jpg',
      description: 'Из чего делают детали для беспилотников, вертолётов и атомных станций? Из композитов! Почему этот лёгкий и прочный материал постепенно вытесняет традиционные металлы, показали в НЦК.',
      full_description: 'Один из крупнейших производителей композиционных материалов в России. Компания специализируется на разработке и изготовлении изделий из полимерных композитов, которые применяются в строительстве, автомобилестроении, аэрокосмической отрасли и других сферах. Например, здесь создают материалы, с помощью которых можно полностью восстановить и усилить безнадежно износившиеся и разрушенные сооружения – здания, путепроводы, мосты, делают детали беспилотников, вертолетов, атомных станций и автомобилей.Композит легкий и прочный, он постепенно вытесняет традиционные металлы из всех областей, в которых нужна легкость, прочность и стойкость к коррозии. На предприятии материал разрабатывают, испытывают, сертифицируют и производят серийно.'
    }
  }, {
    id: 'chudotehniki',
    votes: 0,
    video: 'https://www.youtube.com/watch?v=S89jegyn-RE',
    links: {
      vkontakte: 'https://vk.com/rbtshki',
      instagram: 'https://www.instagram.com/rbtshki/',
      youtube: 'https://www.youtube.com/channel/UCRP4EhX1Op-jL7D87PB3qhQ/videos',
    },
    aboutBg: 'images/slider/blogger_04_bg.jpg',
    color: '#285185',
    cover: 'images/bloggers/bloggers_04.jpg',
    logoLg: 'images/blog-logo-lg-4.png',
    title: 'Чудо техники',
    title_parts: ['Чудо', 'техники'],
    category: 'Как делают оптоволокно?',
    backgroundImage: 'images/blog-4.jpg',
    logo: 'images/blog-logo-4.png',
    company: {
      title: 'ОПТИКОВОЛОКОННЫЕ СИСТЕМЫ',
      link: 'http://rusfiber.ru/',
      picture: 'images/location-4.jpg',
      description: 'На чём держится передача данных? Какие они, современные кабели связи? Информация — с первого в России завода, где делают телекоммуникационное оптическое волокно.',
      full_description: 'АО «Оптиковолоконные Системы» - первый в России завод по производству телекоммуникационного оптического волокна. Инвесторами общества являются ОАО "Газпромбанк", ОАО "РОСНАНО", Правительство Республики Мордовия. Открытое в 2013, в 2018  году предприятие преодолело рубеж производства в два миллиона километров. Оптическое волокно - это основной элемент всех современных кабелей связи. Основное применение оптические волокна находят в качестве среды передачи на волоконно-оптических телекоммуникационных сетях различных уровней – от межконтинентальных магистралей до домашних компьютерных сетей. Передача информации по оптоволоконным кабелям происходит в сотни раз быстрее, безопаснее и с меньшими потерями чем по медным. Также оптические волокна применяются как сенсоры деформации, температуры и акустики в системах мониторинга.'
    }
  }, {
    id: 'chemestry-light',
    votes: 0,
    video: 'https://www.youtube.com/watch?v=3SuZpxiVy8w',
    links: {
      vkontakte: 'https://vk.com/chemistryeasyru',
      facebook: 'https://www.facebook.com/ChemistryIsEasy/',
      instagram: 'https://www.instagram.com/chemistry_easy/',
      youtube: 'https://www.youtube.com/channel/UCRzZSz5JlSfN6Ba164vqVCg',
    },
    aboutBg: 'images/slider/blogger_05_bg.jpg',
    color: '#f1a019',
    cover: 'images/bloggers/bloggers_05.jpg',
    logoLg: 'images/blog-logo-lg-5.png',
    title: 'Химия-просто',
    title_parts: ['Химия—', 'просто'],
    category: 'Про сердце электромобилей',
    backgroundImage: 'images/blog-5.jpg',
    logo: 'images/blog-logo-5.png',
    company: {
      title: 'ЛИОТЕХ',
      link: 'http://www.liotech.ru/',
      picture: 'images/location-5.jpg',
      description: 'В чём секрет литий-ионных аккумуляторов? Есть ли у них «эффект памяти»? Для точности исследования команда съездила в Новосибирск! Здесь расположен первый в России завод, занимающийся серийным выпуском таких аккумуляторов большой ёмкости.',
      full_description: '«Лиотех» - единственный в России завод по серийному выпуску литий-ионных аккумуляторов большой единичной емкости, предназначенных для применения на электротранспорте и системах хранения энергии. Литий-ионные аккумуляторы – это универсальные химические источники тока, отличающиеся высокой удельной энергией, низким саморазрядом, длительным сроком эксплуатации и отсутствием «эффекта памяти». Они предназначены для использования во всех электронных и электротехнических устройствах с автономным энергоснабжением. Особенно перспективно применение литий-ионных аккумуляторов во всех видах электротранспортных средств и промышленных системах бесперебойного энергоснабжения.'
    }
  }, {
    id: 'sharifov',
    votes: 0,
    video: 'https://www.youtube.com/watch?v=gHSAFFaDNqQ',
    links: {
      vkontakte: 'https://vk.com/artmath',
      facebook: 'https://www.facebook.com/ArthurHennessy',
      instagram: 'https://www.instagram.com/artur_tw/',
      youtube: 'https://www.youtube.com/channel/UCxtTXA5DU1bZHLLomu83zVw',
    },
    aboutBg: 'images/slider/blogger_06_bg.jpg',
    color: '#3f8ac3',
    cover: 'images/bloggers/bloggers_06.jpg',
    logoLg: 'images/blog-logo-lg-6.png',
    title: 'Артур Шарифов',
    title_parts: ['Артур', 'Шарифов'],
    category: 'Суперкомпозиты',
    backgroundImage: 'images/blog-6.jpg',
    logo: 'images/blog-logo-6.png',
    logoXLarge: true,
    company: {
      title: 'OCSIAL',
      link: 'https://ocsial.com/ru/',
      picture: 'images/location-6.jpg',
      description: 'Говорят, углерод — нефть XXI века, а OCSiAl — это новый «Газпром». Какую технологическую революцию придумали в России и как это изменит наш мир, смотрите в видео.',
      full_description: 'OCSiAl разработала и запустила первую технологию промышленного производства одностенных углеродных нанотрубок. Они способны произвести революцию на рынке материалов, радикально меняя их свойства. Среди исключительных свойств нанотрубок – высокая проводимость и термостойкость, прочность и гибкость. OCSiAl является крупнейшим производителем одностенных углеродных нанотрубок в мире. Мощность компании составляет 10 тонн и будет увеличена до 60 тонн в 2018 году и до 310 тонн в 2020 году. Компания представлена в России, Люксембурге, США, Корее, Китае, Гонконге, Японии, Израиле и Индии.'
    }
  }, {
    id: 'qwerty',
    votes: 0,
    video: 'https://www.youtube.com/watch?v=DofuirtCrn4',
    links: {
      vkontakte: 'https://vk.com/qwrtru',
      youtube: 'https://www.youtube.com/user/QWRTru',
      facebook: 'https://www.facebook.com/Qwertynews/',
      instagram: 'https://www.instagram.com/qwrtru/',
    },
    aboutBg: 'images/slider/blogger_07_bg.jpg',
    color: '#20828b',
    cover: 'images/bloggers/bloggers_07.jpg',
    logoLg: 'images/blog-logo-lg-7.png',
    title: 'QWERTY',
    category: 'Зачем нужны прививки?',
    backgroundImage: 'images/blog-7.jpg',
    logo: 'images/blog-logo-7.png',
    company: {
      title: 'НАНОЛЕК',
      link: 'http://www.nanolek.ru/',
      picture: 'images/location-7.jpg',
      description : 'Ответ на насущный вопрос искали у производителя детских вакцин для Национального календаря прививок. Здесь также производят препараты для терапии ВИЧ и редких генетических заболеваний.',
      full_description: 'НАНОЛЕК – российская биофармацевтическая компания с собственным производством, которое соответствует международным и российским стандартам. Компания была создана в 2011 году при участии АО «Роснано». Приоритетным направлением является производство детских вакцин для Национального календаря прививок, а также препаратов для терапии ВИЧ и редких генетических заболеваний.'
    }
  }, {
    id: 'kik-review',
    votes: 0,
    video: 'https://www.youtube.com/watch?v=_K5Ju4LO1-8',
    links: {
      vkontakte: 'https://vk.com/kikobzor',
      youtube: 'https://www.youtube.com/channel/UCrzwOa2lzzPjfiLIn7Y8SrQ',
      facebook: 'https://www.facebook.com/kikobzor',
      instagram: 'https://www.instagram.com/kikobzor/',
    },
    aboutBg: 'images/slider/blogger_08_bg.jpg',
    color: '#0eadd5',
    cover: 'images/bloggers/bloggers_08.jpg',
    logoLg: 'images/blog-logo-lg-8.png',
    title: 'КИК ОБЗОР',
    category: 'Где создают будущее?',
    backgroundImage: 'images/blog-8.jpg',
    logo: 'images/blog-logo-8.png',
    company: {
      title: 'МИКРОН',
      link: 'http://www.mikron.ru/',
      picture: 'images/location-8.jpg',
      description: 'Что зашито в наши кредитные карты и проездные? Что общего у космоса и детской игрушки? О тонкостях технологий, ставших частью повседневной жизни, рассказали лидеры микроэлектроники России.',
      full_description: '«Микрон» – лидер микроэлектроники России, предприятие, которое производит микросхемы и разрабатывает инновационные решения для цифровой экономики. Чипы Микрона работают в миллиардах умных устройств по всему миру. Продукция предприятия используется в торговле и транспорте, банках и больницах, в школах и на стадионах, на заводах и лыжных склонах. Чипы для банковских карт, паспортов, токенов, пропусков, меток для шуб и лекарств - все это создается на “Микроне” и становится частью нашей повседневной жизни. Микросхемы Микрона одинаково надежны и незаменимы в космосе и в детской игрушке.'
    }
  }, {
    id: 'tsatsulin',
    votes: 0,
    video: 'https://www.youtube.com/watch?v=OF4FmJvEhMg',
    links: {
      vkontakte: 'https://vk.com/cavemanstech',
      facebook: 'https://www.facebook.com/cmtsciencecom/',
      youtube: 'https://www.youtube.com/user/CaveMansTech',
      instagram: 'https://www.instagram.com/cmtscience/',
    },
    aboutBg: 'images/slider/blogger_09_bg.jpg',
    color: '#cbca87',
    cover: 'images/bloggers/bloggers_09.jpg',
    logoLg: 'images/blog-logo-lg-9.png',
    title: 'Борис Цацулин',
    title_parts: ['Борис', 'Цацулин'],
    category: 'Лекарства в России',
    backgroundImage: 'images/blog-9.jpg',
    logo: 'images/blog-logo-9.png',
    company: {
      title: 'НОВАМЕДИКА ИННОТЕХ',
      link: 'http://novamedica.com/ru',
      picture: 'images/location-9.jpg',
      description: 'Как «запрограммировать» таблетки? Можно ли «бить» лекарством напрямую в больной орган или точку в нём? Почти фильм об «умных» таблетках, которые заменят нам горсти пилюль в ближайшее время.',
      full_description: 'НоваМедика создала суперсовременный R&D-центр с пилотным производством, где разрабатывает препараты будущего – «умные» таблетки, которые знают, где и когда им нужно раствориться. Ученые «программируют» их так, чтобы они напрямую воздействовали на органы-мишени. Новое подразделение называется «НоваМедика Иннотех». Благодаря инновационным платформам и технологиям, специалисты R&D-центра могут создавать многокомпонентные лекарственные формы, содержащие несовместимые ранее молекулы, еще и с запрограммированным высвобождением действующих веществ. Таким образом пациенту будет достаточно принимать всего одну инновационную пилюлю вместо привычных горстей таблеток в течение дня. Такие решения способствуют повышению эффективности и безопасности терапии.'
    }
  }
];
