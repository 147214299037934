import {Directive, HostBinding, Input} from '@angular/core';

@Directive({
  selector: '[appBlogLogo],[blogLogo]'
})
export class BlogLogoDirective {
  @HostBinding('class.logo') @Input() logo;
  @HostBinding('class.logo-xlarge') @Input() xLarge = false;

  @HostBinding('style.background-image') get backgroundImage() {
    return this.logo ? `url(${this.logo})` : '';
  }
}
