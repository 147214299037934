import {ChangeDetectionStrategy, Component, EventEmitter, OnInit, Output, HostBinding, ViewChild, Inject, Renderer2, Input} from '@angular/core';
import {OwlCarousel} from 'ngx-owl-carousel';
import {MnFullpageService} from 'ngx-fullpage';
import {NgxFacebookService} from '@shtrihpunktir/ngx-facebook';
import {AuthService} from 'src/app/services/auth.service';
import {UserService} from 'src/app/services/user.service';
import {QuizService} from 'src/app/quiz.service';
import {DOCUMENT} from '@angular/common';
import { combineLatest, BehaviorSubject } from 'rxjs';

@Component({
  selector: 'app-quiz, [app-quiz].section-quiz',
  templateUrl: './quiz.component.html',
  styleUrls: ['./quiz.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class QuizComponent implements OnInit {
  @Input() quizActivate: EventEmitter<boolean>;
  @Output() goback = new EventEmitter();

  @HostBinding('class.promo') promoClass: boolean = true;
  @HostBinding('class.started') startedClass: boolean = false;
  @HostBinding('class.finished') finishedClass: boolean = false;

  @ViewChild('questionSlider') questionSlider: OwlCarousel
  // @ViewChild('owlElement') owlElement: OwlCarousel


  readonly owlOptions = {
    items: 1,
    loop: false,
    center: true,
    // autoWidth: true,
    autoRefresh: false,
    nav: true,
    slideTransition: 'ease',
    mouseDrag: false,
    touchDrag: true,
    smartSpeed: 650,
    dots: false,
    onTranslate: (event) => {
      this.currentQuestionsPage.next(event.page.index);
      // console.log('event.page.index', event.page.index)
      // this.activeBlog = this.blogsList[event.page.index];
      // this.cd.detectChanges();
    }
  };

  maxAllowedPage: number = 0;
  currentQuestionsPage: BehaviorSubject<number> = new BehaviorSubject(0);

  canActivatePrevPage: BehaviorSubject<boolean> = new BehaviorSubject(false);
  canActivateNextPage: BehaviorSubject<boolean> = new BehaviorSubject(false);

  answered: any = {};
  responseMap: any = {};

  giftEmail: string = null;

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private renderer: Renderer2,
    private readonly facebook: NgxFacebookService,
    private readonly fullpage: MnFullpageService,
    private auth: AuthService,
    public user: UserService,
    public quiz: QuizService
  ) { }

  ngOnInit() {
    console.log('this.quizActivate', this.quizActivate)
    combineLatest(this.quizActivate, this.isStarted, this.isComplete)
    .subscribe((vals) => {
      let [acticated, started, completed] = vals;
      console.log(acticated, started, !completed)
      if(acticated && started && !completed) {
        this.fullpage.setAllowScrolling(false);
      } else {
        this.fullpage.setAllowScrolling(true);
      }
    })

    const combined = combineLatest(this.isStarted, this.isComplete);
    combined.subscribe(([started, completed]) => {
      console.log(started, completed);

      if(started) {
        this.startedClass = true;
        this.promoClass = false;

        this.renderer.removeClass(document.body, 'quiz-promo');
        this.renderer.addClass(document.body, 'quiz');

        if(completed) {
          this.finishedClass = true;
        }

        // this.fullpage.setAllowScrolling(false);
      } else {
        this.startedClass = false;
        this.promoClass = true;

        this.renderer.addClass(document.body, 'quiz-promo');
        this.renderer.removeClass(document.body, 'quiz');

        // this.fullpage.setAllowScrolling(true);
      }
    });

    this.currentQuestionsPage.subscribe((page) => {
      this.canActivatePrevPage.next(page > 0);
      console.log(page, this.maxAllowedPage)
      this.canActivateNextPage.next((page <= this.maxAllowedPage) && !(page == 0 && this.maxAllowedPage == 0));
    })
  }

  get isAuthenticated() {
    return this.auth.isAuthenticated;
  }

  get isStarted() {
    return this.quiz.isStarted$;
  }

  get isComplete() {
    return this.quiz.isComplete$;
  }

  get canGetGift() {
    return this.quiz.canGetGift$;
  }

  get isEmailSendet() {
    return this.quiz.isEmailSendet$;
  }

  get questionsList() {
    return this.quiz.getQuestions();
  }

  get quizResult() {
    return this.quiz.getQuizResult();
  }

  startQuiz() {
    // console.log('startQuiz');
    this.quiz.startQuiz();
    return false;
  }

  restartQuiz() {
    this.answered = {};
    this.responseMap = {};
  
    for(let item of this.questionsList) {
      for(let _answer of item.answers) {
        _answer.selected = false;
      }
    }

    this.quiz.restartQuiz();
    return false;
  }

  saveEmailForGetGift() {
    this.quiz.saveEmail(this.giftEmail);
    return false;
  }

  onAnswer(idx, $event, step, count) {
    // console.log('onAnswer', $event, step, count);
    if(!this.answered[step]) { this.answered[step] = {} }
    this.answered[step][idx] = 1;

    // console.log('this.answered[step]', this.answered[step]);
    if(Object.keys(this.answered[step]).length >= count) {
      this.maxAllowedPage = step/count;
      this.questionSlider.next();
    }

    this.responseMap[idx] = ($event.valid || false);

    // console.log(Object.keys(this.responseMap).length, this.questionsList.length)
    if(Object.keys(this.responseMap).length == this.questionsList.length) {
      let responseArr = [];
      for(let key in this.responseMap) {
        responseArr.push(this.responseMap[key])
      }

      const summary = responseArr.reduce((pv, cv, ci) => {
        return pv + (cv ? 1 : 0);
      }, 0)

      this.quiz.completeQuiz(summary, responseArr, this.responseMap);
      // console.log('complete', this.responseMap, responseArr, summary)
    }

  }

  shareFacebook($event) {
    $event.preventDefault();
    this.facebook.share({
      href: [location.origin, '#4'].join('/'),
      quote: `Я нанопрокачан на ${this.quizResult.summary} из 10! Смотри видео о нанотехнологиях, голосуй за любимых блогеров и проверь свои знания!`,
      picture: [location.origin, 'assets/logo-nano2.png'].join('/'),
      method: 'share',
      mobile_iframe: false
    }).subscribe();
  }
  
  getVkontakteShareLink() {

    // if (blog) {
    return `https://vk.com/share.php?url=${encodeURIComponent([location.origin, '#4'].join('/'))}&title=${encodeURIComponent(`Я нанопрокачан на ${this.quizResult.summary} из 10! Смотри видео о нанотехнологиях, голосуй за любимых блогеров и проверь свои знания!`)}&image=${encodeURIComponent([location.origin, 'assets/logo-nano2.png'].join('/'))}`;
    // }
    // return '';
  }

  prevQuestionPage() {
    this.questionSlider.previous();

    return false;
  }

  nextQuestionPage() {
    this.questionSlider.next();

    return false;
  }

  clickGoBack() {
    this.goback.emit();
  }

}
