import {AfterViewInit, ChangeDetectorRef, Component, HostBinding, ViewChild, EventEmitter} from '@angular/core';
import {MnFullpageOptions, MnFullpageService} from 'ngx-fullpage';
import {Blog} from './blog';
import {BlogsService} from './blogs.service';
import {OwlCarousel} from 'ngx-owl-carousel';
import {MagnificPopupService} from './modules/magnific-popup';
import {CookieAgreementService} from './services/cookie-agreement.service';
import {getQueryParameter} from './utilites/get-query-param';
import {timer} from 'rxjs';
import {delay, filter, first, take} from 'rxjs/operators';
import {NgxFacebookService} from '@shtrihpunktir/ngx-facebook';
import {FormControl, Validators} from '@angular/forms';
import {SwUpdate} from '@angular/service-worker';
import {Angulartics2GoogleTagManager} from 'angulartics2/gtm';
import {environment} from '../environments/environment';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements AfterViewInit {
  @HostBinding('class.nav-on') navOn = false;
  @ViewChild(OwlCarousel) owlElement: OwlCarousel;

  quizActivate: EventEmitter<boolean> = new EventEmitter();

  readonly fullpageOptions = new MnFullpageOptions({
    navigation: false,
    dots: false,
    responsiveWidth: 801,
    scrollingSpeed: 1000,
    onLeave: () => this.toggleNav(false),
    afterLoad: (anchorLink, index) => {
      console.log('index', index)
      if(index == 4) {
        console.log('emit')
        this.quizActivate.emit(true);
      } else {
        this.quizActivate.emit(false);
      }
    },
  });
  readonly owlOptions = {
    items: 1,
    // loop: true,
    loop: false,
    rewind: true,
    center: true,
    autoWidth: true,
    autoRefresh: false,
    nav: true,
    slideTransition: 'ease',
    mouseDrag: false,
    touchDrag: true,
    smartSpeed: 650,
    onTranslated: (event) => {
      this.activeBlog = this.blogsList[event.page.index];
      this.cd.detectChanges();
    }
  };
  readonly blogsList: Array<Blog>;
  readonly blogsArchiveList: Array<Blog>;
  sortedBlogs: Array<Blog>;
  activeBlog: Blog;
  rulesAccepted = new FormControl(false, Validators.requiredTrue);
  updateData: [string];

  get userVotes$() {
    return this.blogsService.getUserVotesCount();
  }

  constructor(private readonly fullpage: MnFullpageService,
              private readonly blogsService: BlogsService,
              private readonly popup: MagnificPopupService,
              private readonly cookieAgreement: CookieAgreementService,
              private readonly cd: ChangeDetectorRef,
              private readonly facebook: NgxFacebookService,
              private readonly updates: SwUpdate,
              private readonly tracking: Angulartics2GoogleTagManager,
  ) {
    this.tracking.pageTrack(location.href);
    this.blogsList = this.blogsService.getBlogs();
    this.blogsArchiveList = this.blogsService.getBlogsArchive(1);

    this.updateVotes();
    updates.available.subscribe(event => {

      this.updateData = (event.current.appData as any).changelog || [];
      this.popup.openInline('updates-available');
    });


  }

  ngAfterViewInit() {
    this.cookieAgreement.request();
    this.cookieAgreement.accepted$
      .pipe(
        filter(accepted => !!accepted),
        take(1),
        delay(500)
      )
      .subscribe(
        (v) => {
          console.log(v);
          // this.popup.openInline('winner');
        },
        null,
        () => console.log('cookie stream completed')
      );
    let activeBlogId;
    let scrollTo = 0;
    try {
      activeBlogId = getQueryParameter('blog');
      scrollTo = Number(getQueryParameter('scrollTo'));
    } catch (err) {
      console.error(err);
    }
    let activeBlog = this.blogsList.find(blog => activeBlogId === blog.id);
    if (!activeBlog) {
      activeBlog = this.blogsList[Math.floor(Math.random() * this.blogsList.length)];
    }
    this.selectBlog(activeBlog);
    if (scrollTo) {
      this.fullpage.moveTo(scrollTo)
      // this.fullpage.setAllowScrolling(false)
      // this.fullpage.moveSectionDown();
      // this.fullpage.moveSectionDown();
    }
    if (environment.production) {
      //window.history.replaceState({}, document.title, '');
    }

  }

  updateSiteContent() {
    this.updates.activateUpdate().then(() => document.location.reload());
  }

  cookiesAccept() {
    this.cookieAgreement.accept();
  }

  scrollFullpage() {
    this.fullpage.moveSectionDown();
  }

  navHome() {
    this.fullpage.moveTo(1);
  }

  toggleNav(forceTo?) {
    if (forceTo !== undefined) {
      this.navOn = forceTo;
    } else {
      this.navOn = !this.navOn;
    }
  }

  goBack() {
    this.fullpage.moveTo(1);
  }

  viewBlog(blog: Blog) {
    this.selectBlog(blog);
    this.scrollFullpage();
  }

  vote(blog: Blog) {
    if (this.blogsService.isFinished) {
      this.popup.openInline('contest-finished');
      return;
    }
    this.blogsService.vote(blog)
      .subscribe(
        () => {
          this.updateSortedBlogs();
          this.popup.openInline('vote-done');
        },
        (err: Error) => {
          if (/forbidden/.test(err.message)) {
            this.popup.openInline('auth');
          }
          if (/only \d+ votes allowed/i.test(err.message)) {
            this.popup.openInline('vote-max-limit');
          }
        }
      );
  }

  viewBlogVideo(blog: Blog) {
    if (blog.video) {
      this.popup.openIframe(blog.video);
    } else {
      this.popup.openInline('video-not-found');
    }
  }

  private selectBlog(blog: Blog) {
    timer(50)
      .subscribe(() => {
        const index = this.blogsList.indexOf(blog);
        this.activeBlog = blog;
        this.owlElement.to([index, 200]);
        // this.owlElement.next();
      });
  }

  closeModals() {
    this.popup.close();
  }

  getFacebookShareLink(blog: Blog) {
    if (blog) {
      return `https://www.facebook.com/sharer.php?href=${location.origin}/share/${blog.id}`;
    }
    return '';
  }

  shareFacebook($event) {
    $event.preventDefault();
    this.facebook.share({
      href: `${location.origin}/share/${this.activeBlog.id}`,
      method: 'share',
      mobile_iframe: false
    }).subscribe();
  }

  getVkontakteShareLink(blog: Blog) {
    if (blog) {
      return `https://vk.com/share.php?url=${location.origin}/share/${blog.id}`;
    }
    return '';
  }

  private updateVotes() {
    this.blogsService.updateVotes().pipe(first()).subscribe(() => this.updateSortedBlogs());
  }

  private updateSortedBlogs() {
    // this.owlElement.reInit();
    this.sortedBlogs = [...this.blogsList].sort((a, b) => {
      if (a.votes > b.votes) {
        return -1;
      } else if (a.votes < b.votes) {
        return 1;
      } else {
        return 0;
      }
    });
  }
}
