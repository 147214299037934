import {Blog} from '../blog';
/* tslint:disable:max-line-length */
export const BLOGS_DATA: Array<Blog> = [
  {
    id: 'pobedinskiy',
    votes: 0,
    video: 'https://www.youtube.com/watch?v=JoP6q5gGReo',
    links: {
      vkontakte: 'https://vk.com/pobedos',
      instagram: 'https://www.instagram.com/pobedinskiy/',
      youtube: 'https://www.youtube.com/user/PhysFromPobed',
      facebook: 'https://www.facebook.com/PhysFromPobed/?ref=br_rs',
    },
    aboutBg: 'images/slider/blogger_01_bg.jpg',
    color: '#9673b4',
    cover: 'images/bloggers/bloggers_01.jpg',
    logoLg: 'images/blog-logo-lg-1.png',
    backgroundImage: 'images/blogs-list-bg/pobedinskiy.png',
    logo: 'images/blog-logo-1.png',
    title: 'Физика от Побединского',
    category: 'Считаем вольты и амперы.',
    company: {
      title: 'Профотек',
      link: 'http://www.profotech.ru/',
      picture: 'images/locations/location-profotech.jpg',
      description: 'Мало получить электричество — нужно его ещё и измерить! Как современные оптоволоконные технологии меняют отрасль, где годами господствовала медь, — смотрите в видео.',
    }
  }, {
    id: 'thoisoi',
    votes: 0,
    video: 'https://www.youtube.com/watch?v=mKaix3IfqDo',
    links: {
      vkontakte: 'https://vk.com/thoisoi',
      youtube: 'https://www.youtube.com/user/Thoisoi',
      instagram: 'https://www.instagram.com/thoisoi/',
      facebook: 'https://www.facebook.com/thoisoi2/'
    },
    aboutBg: 'images/slider/blogger_10_bg.png',
    color: '#54c4d0',
    cover: 'images/bloggers/bloggers_10-2.png',
    logoLg: 'images/blog-logo-lg-10.png',
    title: 'Thoisoi',
    category: 'Новая жизнь старой бутылки.',
    backgroundImage: 'images/blogs-list-bg/thoisoi.png',
    logo: 'images/blog-logo-10.png',
    company: {
      title: 'ICM GLASS',
      link: 'http://www.icmglass.ru/',
      picture: 'images/locations/location-icmglass.jpg',
      description: 'Куда попадает выброшенная стеклянная бутылка? На единственный в России завод по производству пеностекольного щебня! Это современный теплоизоляционный материал, который применяется в строительстве зданий и дорог. Какой путь на заводе надо пройти стеклобою, чтобы стать утеплителем, — смотрите в видео!',
    }
  }, {
    id: 'nauchpok',
    votes: 0,
    video: 'https://www.youtube.com/watch?v=R_SstawBUB4',
    links: {
      vkontakte: 'https://vk.com/nikitinaa',
      youtube: 'https://www.youtube.com/user/nowchpok',
      instagram: 'https://www.instagram.com/alenasaur/',
    },
    aboutBg: 'images/slider/blogger_02_bg.jpg',
    color: '#02a6e3',
    cover: 'images/bloggers/bloggers_02.jpg',
    logoLg: 'images/blog-logo-lg-2.png',
    title: 'Научпок',
    category: 'Роботы в помощь человеку.',
    backgroundImage: 'images/blogs-list-bg/nauchpok.png',
    logo: 'images/blog-logo-2.png',
    company: {
      title: 'Техноспарк',
      link: 'http://technospark.ru/',
      picture: 'images/locations/location-technospark.jpg',
      description: 'Они работают круглые сутки, поднимают до полутора тонн и сами заботятся о своей зарядке. Уже сейчас роботы способны заменить большую часть сотрудников склада. Как это возможно — смотрите в видео!',
    }
  }, {
    id: 'scione',
    votes: 0,
    video: 'https://www.youtube.com/watch?v=DCAh6Qpodh8',
    links: {
      youtube: 'https://www.youtube.com/channel/UCSaVoRErW4kqKsDqExs2MXA/videos',
      facebook: 'https://www.facebook.com/sci.one.tv',
      vkontakte: 'https://vk.com/sci_one',
      instagram: 'https://www.instagram.com/sci_one_tv/',
    },
    aboutBg: 'images/slider/blogger_03_bg.jpg',
    color: '#5e656d',
    cover: 'images/bloggers/bloggers_03.jpg',
    logoLg: 'images/blog-logo-lg-3.png',
    title: 'SciOne',
    category: 'Как проверить мир, что он реален?',
    backgroundImage: 'images/blogs-list-bg/scione.png',
    logo: 'images/blog-logo-3.png',
    company: {
      title: '<a href="http://mikron.ru/" target="_blank">Микрон</a>, <a href="http://multicore.ru/" target="_blank">Элвис</a> и <a href="https://mapperllc.ru/ru/" target="_blank">Mapper</a>',
      // link: 'http://multicore.ru/',
      picture: 'images/locations/location-zntc.jpg',
      description: 'Не такая уж и безумная идея, что наш мир - компьютерная симуляция. Сегодня только одна технология способна мониторить обстановку в физическом мире на всех уровнях - это сенсоры. Проверить смелую гипотезу отправляемся в Микрон, Элвис и Mapper!',
    }
  }, {
    id: 'chudotehniki',
    votes: 0,
    video: 'https://www.youtube.com/watch?v=5-bhgygTEco',
    links: {
      vkontakte: 'https://vk.com/rbtshki',
      instagram: 'https://www.instagram.com/rbtshki/',
      youtube: 'https://www.youtube.com/channel/UCRP4EhX1Op-jL7D87PB3qhQ/videos',
    },
    aboutBg: 'images/slider/blogger_04_bg.jpg',
    color: '#285185',
    cover: 'images/bloggers/bloggers_04.jpg',
    logoLg: 'images/blog-logo-lg-4.png',
    title: 'Чудо техники',
    category: 'Как приручить энергию Солнца?',
    backgroundImage: 'images/blogs-list-bg/chudotehniki.png',
    logo: 'images/blog-logo-4.png',
    company: {
      title: 'Хевел',
      link: 'http://www.hevelsolar.com/',
      picture: 'images/locations/location-hevelsolar.jpg',
      description: 'Цены на углеводороды постоянно растут, но, к счастью, всего полпроцента солнечной энергии может обеспечить все потребности мировой энергетики. О том, как солнечный свет превращается в привычные нам 220 вольт, — в видео',
    }
  }, {
    id: 'chemestry-light',
    votes: 0,
    video: 'https://www.youtube.com/watch?v=6_TqTdSbOpA',
    links: {
      vkontakte: 'https://vk.com/chemistryeasyru',
      facebook: 'https://www.facebook.com/ChemistryIsEasy/',
      instagram: 'https://www.instagram.com/chemistry_easy/',
      youtube: 'https://www.youtube.com/channel/UCRzZSz5JlSfN6Ba164vqVCg',
    },
    aboutBg: 'images/slider/blogger_05_bg.jpg',
    color: '#f1a019',
    cover: 'images/bloggers/bloggers_05.jpg',
    logoLg: 'images/blog-logo-lg-5.png',
    title: 'Химия-Просто',
    category: 'Керамика — это не только чашки!',
    backgroundImage: 'images/blogs-list-bg/chemestry-light.png',
    logo: 'images/blog-logo-5.png',
    company: {
      title: 'Нэвз-Керамикс',
      link: 'http://www.nevz-ceramics.com/ru/',
      picture: 'images/locations/location-nevz-ceramics.jpg',
      description: 'Думаете, керамика — это посуда и сантехника? У неё гораздо больше применений и возможностей, о которых мы не знаем! Отправляемся в Новосибирск, чтобы познакомиться с материалами из наноструктурированной керамики.',
    }
  }
];
