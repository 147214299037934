import {Injectable} from '@angular/core';
import {JwtHelperService} from '@auth0/angular-jwt';

@Injectable()
export class AuthService {

  constructor(private jwt: JwtHelperService) {
  }

  get isAuthenticated() {
    return this.token && !this.jwt.isTokenExpired();
  }

  get token() {
    return this.jwt.tokenGetter();
  }
}
