import {ChangeDetectionStrategy, Component, Input} from '@angular/core';
import {Blog} from '../blog';

@Component({
  selector: 'app-blog-preview,[blog-preview].preview',
  templateUrl: './blog-preview.component.html',
  styleUrls: ['./blog-preview.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BlogPreviewComponent {
  @Input() blog: Blog;
}
