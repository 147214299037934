import {ChangeDetectionStrategy, Component, Input} from '@angular/core';

@Component({
  selector: 'app-blog-votes',
  templateUrl: './blog-votes.component.html',
  styleUrls: ['./blog-votes.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BlogVotesComponent {
  @Input() votes: number;
}
