import {ChangeDetectionStrategy, Component, EventEmitter, OnInit, Output} from '@angular/core';

@Component({
  selector: 'app-about, [app-about].section-about',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AboutComponent implements OnInit {
  @Output() next = new EventEmitter();

  constructor() {
  }

  ngOnInit() {
  }

  scrollDown() {
    this.next.emit();
  }

}
