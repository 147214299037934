import {ChangeDetectionStrategy, Component, EventEmitter, OnInit, Output, HostBinding, Input} from '@angular/core';
import {MnFullpageService} from 'ngx-fullpage';
import {AuthService} from 'src/app/services/auth.service';
import {UserService} from 'src/app/services/user.service';
import {QuizService} from 'src/app/quiz.service';

@Component({
  selector: 'question-view, [question-view].question-view',
  templateUrl: './question-view.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class QuestionViewComponent implements OnInit {
  @Input() question: any;
  @Output() onAnswer = new EventEmitter();

  constructor() { }

  ngOnInit() {
  }

  selectAnswer(answer) {
    for(let _answer of this.question.answers) {
      _answer.selected = false;
    }
    answer.selected = true;

    this.onAnswer.emit(answer);
  }


}
