// import {Blog} from '../blog';


/* tslint:disable:max-line-length */
export const QUIZ_QUESTIONS_DATA: Array<any> = [
  {
    id: 1,
    questionText: "Кто такой нано?",
    answers: [
      {
        type: "text",
        answerText: "Греческий карлик",
        valid: true
      }, {
        type: "text",
        answerText: "Грузинская дива",
      }, {
        type: "text",
        answerText: "Албанский политик"
      }, {
        type: "text",
        answerText: "Латинский гном",
      }
    ]
  }, {
    id: 2,
    questionText: "До какой степени мала наночастица?",
    answers: [
      {
        type: "text",
        answerText: "До величины подковы у блохи",
      }, {
        type: "text",
        answerText: "Одна миллиардная",
        valid: true
      }, {
        type: "text",
        answerText: "𝟏𝟎<sup><small>−𝟔</small><sup>",
      }, {
        type: "text",
        answerText: "Одна миллионная",
      }
    ]
  }, {
    id: 3,
    questionText: "Графен – это…",
    answers: [
      {
        type: "text",
        answerText: "Графин с опечаткой",
      }, {
        type: "text",
        answerText: "Углеродный наноматериал",
        valid: true
      }, {
        type: "text",
        answerText: "Стержень карандаша",
      }, {
        type: "text",
        answerText: "Вид наноробота",
      }
    ]
  }, {
    id: 4,
    questionText: "Как в СССР называли нанопорошки и наноматериалы?",
    answers: [
      {
        type: "text",
        answerText: "Нанопорошки и наноматериалы",
      }, {
        type: "text",
        answerText: "Миф и мойдодыр",
      }, {
        type: "text",
        answerText: "Ультрадисперсные",
        valid: true
      }, {
        type: "text",
        answerText: "Сверхдисперсные",
      }
    ]
  }, {
    id: 5,
    questionText: "На какой картинке нано?",
    answers: [
      {
        type: "image",
        answerImage: "images/questions-data/5-1.png",
      }, {
        type: "image",
        answerImage: "images/questions-data/5-2.png",
        valid: true
      }, {
        type: "image",
        answerImage: "images/questions-data/5-3.png"
      }, {
        type: "image",
        answerImage: "images/questions-data/5-4.png"
      }
    ]
  }, {
    id: 6,
    questionText: "Чем известен Э. Дрекслер?",
    answers: [
      {
        type: "text",
        answerText: "Отец нанотехнологий",
      }, {
        type: "text",
        answerText: "Футуролог",
      }, {
        type: "text",
        answerText: 'Написал известную книгу "Машины создания"',
      }, {
        type: "text",
        answerText: "Все перечисленное",
        valid: true
      }
    ]
  }, {
    id: 7,
    questionText: "Медицинские нанороботы способны",
    answers: [
      {
        type: "text",
        answerText: "Начать восстание машин",
      }, {
        type: "text",
        answerText: "Объединяться в рой и заменять настоящих врачей",
      }, {
        type: "text",
        answerText: "Воевать с микробами",
      }, {
        type: "text",
        answerText: "Лечить больные клетки человека, двигаясь по его кровеносным сосудам",
        valid: true
      }
    ]
  }, {
    id: 8,
    questionText: "Что из перечисленного является представителем наномира?",
    answers: [
      {
        type: "image",
        answerImage: "images/questions-data/8-1.png",
        valid: true
      }, {
        type: "image",
        answerImage: "images/questions-data/8-2.png"
      }, {
        type: "image",
        answerImage: "images/questions-data/8-3.png"
      }, {
        type: "image",
        answerImage: "images/questions-data/8-4.png"
      }
    ]
  }, {
    id: 9,
    questionText: "Какими инструментами пользуются нанотехнологи?",
    answers: [
      {
        type: "text",
        answerText: "Туннельным микроскопом",
        valid: true
      }, {
        type: "text",
        answerText: "Микродрелью",
      }, {
        type: "text",
        answerText: "Опытным микроскопом",
      }, {
        type: "text",
        answerText: "3D-микроскопом",
      }
    ]
  }, {
    id: 10,
    questionText: "Качер Бровина – это…",
    answers: [
      {
        type: "text",
        answerText: "Известный нанотехнолог – советский эмигрант",
      }, {
        type: "text",
        answerText: "Разновидность генератора на одном транзисторе",
        valid: true
      }, {
        type: "text",
        answerText: "Голливудский актёр",
      }, {
        type: "text",
        answerText: "Советский инженер",
      }
    ]
  }
]

export const QUIZ_RESULTS_DATA: Array<any> = [
  {
    minVal: 0,
    maxVal: 5,
    description: "Ты явно не постиг всю прелесть «нано», ну ничего, попробуй еще раз пройти наноквиз.",
    posterImg: "images/quiz-results/quiz-result-low.png"
  }, {
    minVal: 6,
    maxVal: 8,
    description: "Видно, ты не то чтобы шаришь, но точно держишь руку на пульсе нанотехнологий.",
    posterImg: "images/quiz-results/quiz-result-midle.png"
  }, {
    minVal: 9,
    maxVal: 10,
    description: "Да ты реально нанопрокачен, шаришь в нанотехнологиях и сможешь с легкостью отличить наноробота от Инфузории-туфельки!",
    posterImg: "images/quiz-results/quiz-result-top.png"
  }
]