import {Directive, HostListener, Input} from '@angular/core';
import {Angulartics2} from 'angulartics2';

@Directive({
  selector: '[appGtmTrack]'
})
export class GtmTrackDirective {
  @Input() gtmCategory: string;
  @Input() gtmAction: string;
  @Input() gtmLabel: string;

  constructor(private readonly tracking: Angulartics2) {
  }

  @HostListener('click')
  onClick() {
    this.tracking.eventTrack.next({
      action: this.gtmAction,
      properties: {
        category: this.gtmCategory,
        label: this.gtmLabel,
      }
    });
  }

}
