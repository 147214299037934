import {ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Blog} from '../../../blog';

@Component({
  selector: 'app-blogs-list-item',
  templateUrl: './blogs-list-item.component.html',
  styleUrls: ['./blogs-list-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BlogsListItemComponent implements OnInit {
  @Input() item: Blog = null;
  @Output() select = new EventEmitter();

  constructor() {
  }

  ngOnInit() {
  }

  selectBlog($event) {
    $event.preventDefault();
    this.select.emit(this.item);
  }

}
